.task-metadata {
  display: flex;
  flex-direction: column;

  .task-metadatum {
    display: flex;
    align-items: center;

    & > *:not(:first-child) {
      margin: 0 0 0 15px;
    }
  }

  & > *:not(:first-child) {
    margin: 10px 0 0 0;
  }
}
