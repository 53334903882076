.player-container {
  grid-area: midright;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  & .strip {
    width: 100%;
    cursor: pointer;

    & .bar {
      margin: 10px 0 10px 0;
      width: 100%;
      height: 5px;
      background: #fba60c;

      border-radius: 2px;
      & .progress {
        border-radius: 2px 0 0 2px;
        height: 100%;
        background-color: #9a2862;
      }
    }

    & .time-axis {
      height: 30px;
      width: 100%;
    }
  }

  .controls {
    & .button-group {
      display: flex;
      align-items: center;

      & button.is-highlight {
        font-size: 25px;

        & svg {
          height: 1em;
          width: 1em;
        }
      }

      & button:not(.is-highlight) {
        font-size: 10px;

        & svg {
          height: 1em;
          width: 1em;
        }
      }
    }
  }
}
