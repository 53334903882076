.task-form {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > *:not(:first-child) {
    margin: 15px 0 0 0;
  }
}
