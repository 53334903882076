@import 'blueprint';

.app {
  min-height: calc(100vh - #{$pt-navbar-height});

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
}
