@charset "utf-8";

@import 'blueprint';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #dcdcdc;
  padding-top: $pt-navbar-height;

  .annotator-root {
  }

  .annotator-heading {
    & h4 {
      margin: 0;
    }
  }
}
