.miller-columns {
  position: relative;
  height: 200px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  & > * {
    max-height: 100%;
  }

  & > .miller-column {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 5px;

    & > *:not(:first-child) {
      border-top: 1px solid black;
      padding-top: 10px;
    }
  }
}
