@import '../blueprint';

.annotator-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  & > *:not(:first-child) {
    margin: 10px 0 0 0;
  }

  .annotator-top-level {
    display: flex;
    justify-content: space-between;
  }
}
