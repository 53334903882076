@import '../blueprint.scss';

.annotations-container {
  display: grid;
  grid-template-columns: 31px 1200px;
  grid-template-rows: auto 200px auto auto;
  grid-template-areas:
    'title title'
    'topleft topright'
    '. midright'
    'bottom bottom';

  .title-header {
    grid-area: title;

    .title {
      display: flex;
      align-items: center;

      & > *:not(first-child) {
        margin: 0 0 0 5px;
      }
    }
  }

  .left-axis {
    grid-area: topleft;
    width: 100%;
  }

  .annotation-layer {
    grid-area: topright;
    place-self: stretch stretch;
    position: relative;

    .annotation-medium {
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    .annotations {
      position: absolute;
      height: 100%;

      & > * {
        position: absolute;
      }

      & > .annotation-marker {
        min-height: 10%;
        height: 10%;

        background-color: rgba($green1, 0.3);
        border: solid 1px $green1;
        z-index: auto;

        overflow: hidden;

        &:hover {
          height: auto;

          background-color: rgba($green1, 1);
          z-index: 10;

          overflow: visible;
        }

        .annotation-label {
        }
      }

      & > .current-annotation {
        height: 100%;
        background-color: rgba($pt-intent-primary, 0.2);
        border-left: solid 1px $pt-intent-primary;
        border-right: solid 1px $pt-intent-primary;
      }

      .popover-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;

        * {
          display: inherit;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.task-controls {
  grid-area: bottom;
  width: 100%;

  display: flex;
  justify-content: space-around;
}
